<template>
  <footer class="footer">
    <div class="footer__desc">
      <p>
        Услугу подбора предложений предоставляет Усов Алексей Виикторович, ИНН:
        526004541390, ОГРНИП: 306526023600011. Юридический адрес: 603093,
        Нижегородская область, г Нижний Новгород, ул. Усилова, д. 8., кв. 8.
      </p>
      <p>
        Выдача займов у партнеров осуществляется в российских рубля, на
        банковский счет, наличными или на карту, гражданам РФ. Сайт не относится
        к финансовым учреждениям, не является кредитором или банком и не несет
        ответственности за заключенные впоследствие клиентами и партерами
        договоры микрокредитования. Все сведения на сайте носят информационный
        характер. Все рекомендуемые на сайте учреждения имеют соответствующие
        лицензии.
      </p>
    </div>
  </footer>
</template>

<script>
import "./footer.scss";

export default {
  name: "Footer",
  computed: {
    text() {
      return this.$DICTIONARY.footerText;
    },
  },
};
</script>
